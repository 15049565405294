import React, { useState } from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

import HowItWorksStyle from "assets/jss/material-kit-react/components/HowItWorksStyle.jsx"

const HowItWorksCard = ({ ...props }) => {
  const { classes, title, description, number } = props

  return (
    <div className={classes.infoArea}>
      <div className={classes.descriptionWrapper}>
        <div className={classes.titleWrapper}>
          <h4 className={classes.number}>
            {number}
          </h4>
          <h4 className={classes.title}>
            {title}
          </h4>
        </div>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  )
}

HowItWorksCard.defaultProps = {}

HowItWorksCard.propTypes = {
  classes: PropTypes.object.isRequired,
  showcaseImage: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.node).isRequired,
}

export default withStyles(HowItWorksStyle)(HowItWorksCard)
