import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  title,
} from "assets/jss/material-kit-react.jsx"

const infoStyle = {
  infoArea: {
    textAlign: "left",
    maxWidth: "360px",
    margin: "0 auto",
    padding: "0px",
  },
  number: {
    background: "#3b4958",
    width: 30,
    height: 30,
    borderRadius: 50,
    color: "white",
    textAlign: "center",
    marginRight: 10,
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px",
  },
  primary: {
    color: primaryColor,
  },
  warning: {
    color: warningColor,
  },
  danger: {
    color: dangerColor,
  },
  success: {
    color: successColor,
  },
  info: {
    color: infoColor,
  },
  rose: {
    color: roseColor,
  },
  gray: {
    color: grayColor,
  },
  icon: {
    width: "36px",
    height: "36px",
  },
  descriptionWrapper: {
    color: grayColor,
    overflow: "hidden",
  },
  title,
  description: {
    color: grayColor,
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "14px",
  },
  iconWrapperVertical: {
    float: "none",
  },
  iconVertical: {
    width: "61px",
    height: "61px",
  },
}

export default infoStyle
