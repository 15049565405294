import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons
import Chat from "@material-ui/icons/Chat"
import VerifiedUser from "@material-ui/icons/VerifiedUser"
import Fingerprint from "@material-ui/icons/Fingerprint"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import InfoArea from "components/InfoArea/InfoArea.jsx"

import howItWorksStyle from "assets/jss/material-kit-react/views/landingPageSections/howItWorksStyle.jsx"
import HowItWorksCard from "../../../components/HowItWorksCard"

const HowItWorksSection = (props) => {
  const { classes } = props
  return (
    <div className={classes.section} id="howItWorks">
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Comment ça marche ?</h2>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer justify="center" rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
          <GridItem xs={12} sm={12} md={4}>
            <HowItWorksCard
              title="Prise de contact"
              description="Par le biais du formulaire de contact puis sous la forme d’un appel téléphonique, nous discuterons ensemble pour échanger sur vos idées et désirs concernant le projet de décoration en question. C’est à ce moment là que nous établirons un « cahier des charges » pour votre projet."
              number={1}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <HowItWorksCard
              title="Estimation du projet"
              description="Après la prise de contact, nous étudierons votre projet et nous estimerons le coût que cela engendre afin de vous proposer une esquisse qui réponde à toutes vos attentes tout en étant en accord avec votre budget. Nous nous engageons à vous faire un retour sous 72h!"
              number={2}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <HowItWorksCard
              title="Livraison du rendu"
              description="Une fois que vous aures validé notre devis, suivant la grandeur et les contraintes qu’il possède, nous vous rendrons le projet terminé dans un intervalle de une à deux semaines."
              number={3}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}

export default withStyles(howItWorksStyle)(HowItWorksSection)
